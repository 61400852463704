import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TZKT_API_URL = 'https://api.tzkt.io/v1';

let seenTokens = new Set();
let recentTokens = [];
const MAX_RETRIES = 3;
const ARTIST_SHOW_LIMIT = 15;
const RECENT_TOKENS_LIMIT = 50;
const artistFrequency = new Map();

const fetchTotalPages = async (limit) => {
  try {
    const response = await axios.get(`${TZKT_API_URL}/tokens/count`);
    const totalTokens = response.data;
    return Math.ceil(totalTokens / limit);
  } catch (error) {
    console.error('Error fetching total token count:', error);
    return 100;
  }
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const fetchTokenBatch = async (page = 0, retries = 0, totalPages = 100, latest = false) => {
  if (retries >= MAX_RETRIES) {
    throw new Error('No valid tokens found after multiple attempts. Please try again later.');
  }

  try {
    const limit = 150;
    let tokens = [];

    if (latest) {
      const latestTimestamp = new Date(Date.now() - 168 * 60 * 60 * 1000).toISOString();
      const response = await axios.get(
        `${TZKT_API_URL}/tokens?limit=${limit}&sort.desc=firstTime&metadata.artifactUri.null=false&select=metadata,metadata.artifactUri,metadata.name,metadata.formats,contract,address,tokenId&firstTime.ge=${latestTimestamp}`
      );
      tokens = response.data;
    } else {
      const pagesToFetch = 3;
      const fetchPromises = [];
      for (let i = 0; i < pagesToFetch; i++) {
        const randomPage = Math.floor(Math.random() * totalPages);
        fetchPromises.push(
          axios.get(
            `${TZKT_API_URL}/tokens?limit=${limit}&offset=${randomPage * limit}&sort.desc=lastActivity&select=metadata,metadata.artifactUri,metadata.name,metadata.formats,contract,address,tokenId`
          )
        );
      }
      const responses = await Promise.all(fetchPromises);
      tokens = responses.flatMap((response) => response.data);
    }

    const shuffledTokens = shuffleArray(tokens);

    let validTokens = shuffledTokens.filter((token) => {
      const artistAddress = token.contract.address;
      const currentCount = artistFrequency.get(artistAddress) || 0;
      const tokenId = token.tokenId;

      if (seenTokens.has(tokenId) || recentTokens.includes(tokenId)) {
        return false;
      }

      if (currentCount >= ARTIST_SHOW_LIMIT) {
        return false;
      }

      artistFrequency.set(artistAddress, currentCount + 1);
      seenTokens.add(tokenId);

      const hasMetadata = token.metadata !== undefined && token.metadata !== null;
      const hasArtifactUri =
        hasMetadata &&
        token.metadata.artifactUri &&
        typeof token.metadata.artifactUri === 'string' &&
        token.metadata.artifactUri.trim() !== '';
      const hasContract = token.contract && token.contract.address;

      return hasMetadata && hasArtifactUri && hasContract;
    });

    if (validTokens.length === 0) {
      console.warn('No valid tokens found. Relaxing filters to find more artworks.');
      return fetchTokenBatch(page + 1, retries + 1, totalPages, latest);
    }

    if (validTokens.length < limit) {
      seenTokens.clear();
    }

    const randomToken = validTokens[Math.floor(Math.random() * validTokens.length)];

    recentTokens.unshift(randomToken.tokenId);
    if (recentTokens.length > RECENT_TOKENS_LIMIT) {
      recentTokens = recentTokens.slice(0, RECENT_TOKENS_LIMIT);
    }

    const format = randomToken.metadata.formats ? randomToken.metadata.formats[0] : null;
    const mimeType = format ? format.mimeType : null;
    const isVideo = mimeType === 'video/mp4' || mimeType === 'video/quicktime';
    const isInteractive = mimeType === 'application/x-directory';

    const ipfsHash = randomToken.metadata.artifactUri.replace('ipfs://', '');
    const artifactUrl = `https://assets.objkt.media/file/assets-003/${ipfsHash}/artifact`;
    const objktUrl = `https://objkt.com/tokens/${randomToken.contract.address}/${randomToken.tokenId}?ref=tz1eJdkMoD2fqm5cwzrv7bNT5U1UNzWLybsi`;

    return {
      name: randomToken.metadata.name || 'Untitled',
      artifactUri: artifactUrl,
      objktUrl: objktUrl,
      isVideo: isVideo,
      isInteractive: isInteractive,
    };
  } catch (error) {
    console.error('Error fetching token batch:', error);
    throw new Error('Unable to fetch diverse tokens. Please try again later.');
  }
};

// Main ArtShuffle component to display fetched artworks
const ArtShuffle = () => {
  const [art, setArt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalPages, setTotalPages] = useState(100);
  const [latestMints, setLatestMints] = useState(false);
  const [shuffleCount, setShuffleCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [showHelp, setShowHelp] = useState(false); // State for help modal

  const fetchArt = async (latest = false) => {
    setLoading(true);
    setError('');
    try {
      if (!totalPages) {
        const fetchedTotalPages = await fetchTotalPages(75);
        setTotalPages(fetchedTotalPages);
      }
      const randomArt = await fetchTokenBatch(0, 0, totalPages, latest);
      if (latest) {
        recentTokens = [];
        seenTokens.clear();
      }
      setArt(randomArt);
      setShuffleCount((prevCount) => prevCount + 1);
      setAnimate(true);
      setTimeout(() => setAnimate(false), 300);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArt();

    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
        fetchArt(latestMints);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const refreshArt = () => {
    fetchArt(latestMints);
  };

  const toggleLatestMints = () => {
    setLatestMints(!latestMints);
    fetchArt(!latestMints);
  };

  const toggleHelpModal = () => {
    setShowHelp(!showHelp);
  };

  return (
    <div className="art-shuffle-container">
      <div className="art-shuffle">
        <h1>Tezos Art Shuffle</h1>
        <p className="tagline">
          Discover Hidden Gems, One Click at a Time.
          <span className="tooltip"><br></br> (Tip: Press Spacebar to Shuffle! 🔄)<br/></span>
        </p>
        {art && <h2 className="art-title">{art.name || 'Untitled'}</h2>}
        <p className="shuffle-count">Shuffles: {shuffleCount}</p>
        {loading ? (
          <div className="loading-container">
            <div className="loading-animation"></div> {/* Custom loading animation */}
          </div>
        ) : error ? (
          <div className="error-container">
            <p style={{ color: 'red' }}>{error}</p>
          </div>
        ) : art ? (
          <div className={`art-piece ${animate ? 'animate' : ''}`}>
            <div className="artwork-container">
              {art.isInteractive ? (
                <iframe
                  src={art.artifactUri}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '8px',
                    objectFit: 'contain',
                  }}
                  title={art.name || 'Interactive Artwork'}
                  loading="lazy"
                  sandbox="allow-scripts allow-same-origin"
                ></iframe>
              ) : art.isVideo ? (
                <video
                  src={art.artifactUri}
                  controls
                  muted
                  autoPlay
                  loop
                  style={{ width: '100%', height: '100%', borderRadius: '8px', objectFit: 'contain' }}
                  onError={(e) => console.error('Video failed to load:', e)}
                />
              ) : (
                <img
                  src={art.artifactUri}
                  alt={art.name || 'Artwork'}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: '8px',
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <p>No art found. Please try again.</p>
        )}
        <div className="button-group">
          <button onClick={refreshArt}>Shuffle OBJKT 🔄</button>
          <button
            onClick={toggleLatestMints}
            className={`toggle-button ${latestMints ? 'active' : 'default'}`}
          >
            {latestMints ? 'Back to Random Shuffle' : 'View Latest Mints'}
          </button>
          {art && (
            <a href={`${art.objktUrl}`} target="_blank" rel="noopener noreferrer">
              <button>View on OBJKT</button>
            </a>
          )}
          <button className="help-button" onClick={toggleHelpModal}>?</button> {/* Help Button */}
        </div>

        {showHelp && (
          <div className="help-modal">
            <div className="modal-content">
              <button className="close-button" onClick={toggleHelpModal}>✖</button>
              <h2>How to Use Art Shuffle</h2>
              <p>Use the spacebar to quickly shuffle through artworks!</p>
              <p>Click "Shuffle OBJKT" to discover new hidden gems.</p>
              <p>Toggle between the latest mints and random artworks using the button provided.</p>
              <p>Enjoy exploring art!</p>
            </div>
          </div>
        )}

        <div className="support-message">
          <p>
            Every purchase made through Art Shuffle directly supports artists and the development of
            this app. Thank you for using our referral links! 💜
          </p>
        </div>

        <footer>
          Data provided by{' '}
          <a href="https://tzkt.io" target="_blank" rel="noopener noreferrer">
            TzKT API
          </a>{' '}
          <br />
          Built by{' '}
          <a href="https://linktr.ee/missgoodlife" target="_blank" rel="noopener noreferrer">
            EVO
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ArtShuffle;
